* {
  font-family: "Noto Sans Thai", sans-serif;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  box-sizing: border-box;
  font-size: 1rem;
}

html {
  /* font-size: calc(60% + 0.8vmin); */
  font-size: calc(16px + 0.1vw);
}

main {
  max-width: 40rem;
  margin: 0 auto;
}

h1 {
  font-size: 2.2rem;
}

h2 {
  font-size: 1.6rem;
}

h3 {
  font-size: 1.3rem;
}

h4 {
  font-size: 1.2rem;
}

.container-full {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0 auto;
  /* background-color: dodgerblue;
  background-image: linear-gradient(dodgerblue, white); */
  background-color: whitesmoke;
}

body {
  font-size: 1rem;
  font-weight: 100;
  line-height: 1.25rem;
  color: black;
  background-color: whitesmoke !important;
}

.table-fix-head thead {
  position: sticky;
  top: 6.25rem;
}

a:link {
  text-decoration: none !important;
}

.zoom:hover {
  transform: scale(4.9);
  transform-origin: left top;
  transition: transform 0.5s ease-in-out;
  cursor: zoom-in;
}

.remarkable:hover {
  transform: scale(1.03);
  transform-origin: left top;
  /*transition: transform 0.5s ease-in-out;*/
  /* cursor: zoom-in; */
}

.logo-com2 {
  width: 40%;
  height: 150rem;
  border-radius: 5px;
}

.logo-com {
  width: 100%;
  height: 120px;
  /* object-fit: fill; */
  /* background-image: url('/logo.jpg')  no-repeat center contain;  */
  /* background: url('../../public/images/logo.jpg') no-repeat; */
}

.logo--small {
  display: flex;
  justify-content: right;
  width: 30%;
  height: 60%;
}

.header__menu {
  width: 100%;
  /* height: 6.25rem; */
  height: 120px;
  margin: 0 auto;
  padding: 0;
  display: grid;
  grid-template-columns: 20% 80%;
  /* grid-template-rows: 6rem; */  
  /* position: fixed; */
  position: sticky;
  top: 0px;
  right: 0px;
  box-shadow: 0 5px 10px #888888;
  background-color: white;
  z-index: 9999;
}

.header__menu--right {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: dodgerblue;
  font-size: 3rem;
  font-weight: 600;
}

.header__menu--right--default {
  position: relative;  
  width: 100%;
  height: 100%;
  padding: 0;    
}

.font--menu {
  font-size: 1.6rem; 
}

.font--menu--count {
  font: 1rem;
}

.header__menu--right--top {
  color: blue;
  font-size: 1.1rem;
  margin-right: 0.5rem;
}

.header--bottom {
  /* position: absolute;   */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0;
  align-items: end !important;
  background-color: blue !important;
}

.headerFull {
  /* top: 100px; */
  width: 100%;
  height: 34rem;
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  margin: 10 auto;
  box-shadow: 0 2px 100px whitesmoke;
}

.headerFull2:before {
  content: "";
  width: 100%;
  /* height: inherit; */
  height: 100%;
  /* background-image: url('https://source.unsplash.com/1223x840/?nature'); */
  /* background-image: url("marek-levak-lg.jpg"); */
  background-image: url("building2.jpg");
  opacity: 0.85;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  object-fit: cover;
  position: absolute;
  top: 0px;
  z-index: -1;
}

.headerFull-text {
  position: relative;
  width: 100%;
  /* height: 100%; */
  height: 250px;
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-top: 0px;
  color: darkorange;
  letter-spacing: 1.3px;
  user-select: none;
  line-height: 4rem;
  background-image: url("business-g1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.headerFull-text p {
  font-Size: 4rem;
  /* color: white; */
  text-align: end;
  padding-right: 10%;
}

.headerFull-text p:nth-child(1) {
  font-Size: 6rem;
}

.video-first {
  /* height: 580px; */
  height: 100%;
  width: 100%;
  object-fit: fill;
  position: absolute;
  z-index: -100;
  /* opacity: 90%; */
}

.header--service {
  position: relative;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0;
  object-fit: contain;
  background-size: contain;
}

 .carousel {
  max-height: 700px;
  width: 100%; 
}

.carousel-inner {
  width: 100%;
  height: 100%;
}

.carousel-item {
  height: 100%;
  width: 100%;
}

.carousel-item img {   
  min-width: 100%;
  height: 700px;
  /* object-fit: contain; */
}

.carousel-indicators {
  color: blue !important;
  width: 100%;
}

.header--service {
  max-height: 800px;
  width: 100%;
}

.header--service--text {
  position: relative;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  object-fit: contain;
  clear: both;
}

.header--step {
  position: relative;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  object-fit: cover;
  overflow: hidden;
}

.header--step header {
  width: 83%;
}

.header--step--text {
  color: blue;
}

.proposal-full {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0 auto;
  background-color: whitesmoke;
  /*background-image: linear-gradient(dodgerblue, white); */
}

.proposal-header {
  font-size: 1.5rem;
  font-weight: 600;
  align-items: center;
  margin: 0 auto;
  padding: 10px;
}

.proposal {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.download-text {
  font-size: 1rem;
}

.download-app {
  width: 70%;
  height: 70%;
}

.visitor {
  font-size: 1.3rem;
  justify-self: right;
}

.icon-lg {
  font-size: 7rem;
}

.icon-md {
  font-size: 2rem;
}

.icon-sm {
  font-size: 1.5rem;
}

.icon-intro-lg {
  font-size: 5rem;
}

.icon-red {
  color: red;
}

.icon-green {
  color: green;
}

.icon-blue {
  color: blue;
}

.icon-dodgerblue {
  color: dodgerblue;
}

.icon-orange {
  color: orange;
}

.icon-white {
  color: white;
}

@keyframes move-accounting {
  from {
    top: -300px;
  }

  to {
    top: 0px;
  }
}

@keyframes move-service {
  0% {
    top: 0px;
    color: dodgerblue;
  }

  25% {
    top: 0px;
    color: deepskyblue;
  }

  50% {
    top: 0px;
    color: darkorange;
  }

  75% {
    top: 0px;
    color: orange;
  }

  100% {
    top: 200px;
    color: white;
  }
}

.gradient-text {
  background: linear-gradient(to bottom, darkorange, white);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.gradient-text-blue {
  background: linear-gradient(to bottom, blue, white);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.service--header {
  position: relative;
  display: flex;
  column-gap: 1.25rem;
}

/*color grandient text*/
.service-color {
  background: linear-gradient(to right, blue 0%, dodgerblue 60%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.service-color2 {
  background: linear-gradient(to right, blue 40%, dodgerblue 60%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-size: 2.6rem;
  font-weight: 800;
}

.img-content {
  height: 12.5rem;
  max-width: 100%;
  border-radius: 5px;
  padding: 0px;
}

.img-content+div {
  padding: 10px;
}

.menu-header {
  width: 100%;
  height: 5rem;
  margin: 0 auto;
  background: dodgerblue;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 9999;
}

.nav-header {
  width: 100%;
  background: dodgerblue;
  height: 3.125rem;
  color: white;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
}

.nav-header ul li {
  display: inline-block;
}

nav ul li {
  display: inline-block;
}

.nav-l {
  width: 10%;
  /* height: 40px; */
  height: 100%;
  /* margin-top: 0 auto; */
  background-color: white;
  display: flex;
  justify-content: center;
  position: relative;
}

.triangle-left2 {
  width: 0;
  height: 0;
  border-bottom: 50px solid dodgerblue;
  border-left: 100px solid transparent;
  position: absolute;
  top: 0;
  left: 22.5%;
}

.nav-r {
  width: 90%;
  height: 2.5rem;
  font-size: 20px;
  font-weight: 400;
  margin: 0 20px;
  padding: 15px 0;
}

.nav-r ul {
  float: right;
}

.nav-r ul>li {
  padding: 0 30px;
}

.logo {
  width: 60%;
  height: 100%;
  object-fit: cover;
}

.nav-menu {
  height: 2.813rem;
  background-color: white;
  padding: 8px 0;
  font-size: 18px;
  font-weight: 600;
}

.nav-menu ul {
  float: right;
}

.nav-menu ul>li {
  padding: 0 40px;
}

.ul-menu {
  font-size: 15px;
  margin: 0px;
  padding: 10px;
  overflow: hidden;
  float: right;
}

.ul-menu li {
  display: inline;
  margin-right: 100px;
}

.grid-container ul {
  padding: 15px 0px 0px 15px;
}

.intro-header {
  position: relative;
  width: 80%;
  /* height: auto; */
  height: 500px;
  background-color: white;
  /* margin: 60px auto 40px;  */
  margin: 50px auto;
  /* background-image: url("success.jpg"); */
  /* background-image: url("sv04.jpg"); */
  /* opacity: 0.90; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  object-fit: contain;
  color: dodgerblue;
}

.intro {
  position: relative;
  /* display: block; */
  display: flex;
  flex-direction: column;
  /* display: grid; */
  /* grid-template-columns: 5fr 2.5fr 2.5fr;
  grid-template-columns: 70% 30%; */
  height: 100%;
  width: 100%;
  margin-top: 0px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  font-size: 4rem;
  line-height: 5rem;
  background-color: dodgerblue;
  color: white;
  padding-top: 40px;

  /* background-image: url('../presentation.jpg')  no-repeat center contain; */
  background-image: url('../presentation2.jpg');
  background-repeat: no-repeat;
  background-size: 85% 100%;
  background-position: center;
}

.intro--text {
  /* background: linear-gradient(to bottom, blue, deepskyblue);  
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: white;
  font-size: 4rem;
}

.intro div:nth-child(1) {
  font-size: 4rem;
  color: white;
}

.intro div:nth-child(2) {
  font-size: 3rem;
  color: orange;
}

.intro div:nth-child(3) {
  font-size: 4rem;
  color: white;
}

.intro div:nth-child(4) {
  font-size: 2rem;
  color: white;
}

.intro div:nth-child(5) {
  font-size: 2rem;
  color: white;
}

/* .intro--right {
  position: relative;
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 4rem;
}

.intro--tel {
  display: grid;
  grid-template-columns: 3fr 7fr;
  background-color: orange;
  border-radius: 10px;
  width: 90%;
  height: auto;
  font-size: 1.8rem;
  align-items: center;
  line-height: 2rem;
  padding: 10px;
} */

.intro--line {
  background-color: green;
}

.intro--left {
  text-align: left;
}

.intro--left div {
  font-size: 1.5rem;
}

.intro-container {
  position: relative;
  width: 80%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1.25rem;
  margin: 5px auto;
  z-index: 1;
}

.intro-item {
  width: 100%;
  height: auto;
  text-align: center;
  border-radius: 10px;
  line-height: 3.125rem;
  font-size: 2rem;
  font-weight: 800;
  margin: 0 auto;
  padding: 10px;
  color: dodgerblue;
  /* transition: width 1s, height 1s, transform 1s; */
  /* box-shadow: 1px 1px 10px gray; */
}

/* .intro-item-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.intro-item-header {
  font-size: 1.7rem;
}

.intro-item u {
  font-size: 1.3rem;
}

.intro-item div:nth-child(2) {
  background-color: white;
  height: 9.375rem;
  padding-top: 1.25rem;
}

/*.intro-item img {
  height: 170px;
  width: 100%;
} */

/* .intro-item:hover {
  background-color: blue;
} */

.main--intro {
  position: relative;
  /* display: flex;
  flex-direction: column;   */
  width: 80%;
  min-height: 79vh;
  background-color: white;
  /* top: 80px; */
  margin: 2.5rem auto 1.25rem;
  padding: 3.125rem;
  line-height: 1.25rem;
  /* color: dodgerblue; */
  border-radius: 10px;
  font-weight: 400;
  font-size: 1rem;
}

.main--intro div:last-child {
  color: black;
}

.intro-container-details {
  width: 70%;
  height: 90vh;
  background-color: white;
  position: relative;
  top: 100px;
  margin: 50px auto;
  padding: 20px;
  line-height: 25px;
  color: black;
  border-radius: 10px;
  font-weight: 400;
  font-size: 20px;
}

.intro-container-details div:first-child {
  color: dodgerblue;
  font-size: 20px;
  padding: 10px;
}

.intro-container-details div:nth-child(2) {
  color: black;
  font-size: 18px;
}

.img-detail {
  width: 18.75rem;
  height: 12.5rem;
  object-fit: cover;
  float: left;
  border-radius: 10px;
  margin-right: 20px;
}

.image-icon-lg {
  color: orange;
  height: 10rem;
  width: 18.75rem;
}

.image-icon-md {
  color: orange;
  height: 6.25rem;
  width: 12.5rem;
}

.image-icon-sm {
  color: orange;
  height: 1.875rem;
  width: 2.5rem;
}

.introbox {
  width: 20.625rem;
  height: 12.5rem;
  color: white;
  border: 1px solid dodgerblue;
  display: block;
}

table tr td {
  padding: 1px !important;
}

table thead {
  color: white;
  background-color: dodgerblue;
}

.headerColor {
  color: dodgerblue;
}

.header-sticky {
  position: sticky;
  top: 0;
  width: 100%;
}

.grid-fluid {
  width: 100%;
  /* background: linear-gradient(whitesmoke, white); */
  background-color: whitesmoke;
  color: black;
  font-weight: 300;
  font-size: 1rem;
  position: relative;
  margin: 0 auto;
  padding-bottom: 3.125rem;
  user-select: none;
  z-index: 1;
}

.content--sevices {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 80%;
  font-size: 1rem;
  color: blue;
}

.grid-container {
  display: grid;
  /*grid-template-areas:
    "section1 section2 section3"
    "section4 section5 section6";*/
  grid-template-columns: repeat(2, 1fr);
  width: 80%;
  margin: 0 auto;
  row-gap: 2rem;
  column-gap: 4rem;
  /* justify-self: center; */
}

.grid-scope {
  background-color: white;
  /* background-image: linear-gradient(whitesmoke, white); */
  color: black;
  font-size: 20px;
  font-weight: 400;
  /* border-radius: 10px; */
  margin-top: 20px;
  padding: 30px 20px 30px 50px;
  /* box-shadow: 10px 10px 10px gray; */
  border: 1px solid lightblue;
}

.content__header--mian {
  /* background: linear-gradient(to right, blue 40%, dodgerblue 60%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent; */
  /* font-size: 2.5rem; */
  color: dodgerblue;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 4rem;
  align-self: center;
  align-items: center;
}

.content__header--service {
  padding: 1rem;
  background-color: dodgerblue;
  color: white;
  font-size: 1.5rem;
  font-weight: 800;
}

.content__header--service--item {
  font-weight: 400;
}

.content__header--service--acctax {
  position: relative;
  width: 90%;
  height: 97%;
}

.accnew-download {
  position: relative;
  width: 60%;
  min-height: 627px;
  margin: 5rem auto;
  font-size: 1.5rem;
  font-weight: 500;
  background-color: white;
  border-radius: 5px;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  line-height: 4rem;
  padding: 10px;
}

.accnew-download-grid {
  width: 100%;
  color: dodgerblue;
  display: grid;
  grid-template-columns: 40% 20% 40%;
  padding-left: 2.5rem;
  align-items: center;
}

.accnew-download-grid:hover {
  font-size: 1.8rem;
}

.service1-6 {
  position: relative;
  width: 75%;
  min-height: 100vh;
  top: 2.5rem;
  text-align: left;
  margin: 0px auto;
  margin-bottom: 6.25rem;
  /* padding: 60px 30px 40px 80px; */
  padding: 3.75rem 1.875rem 2.5rem 5rem;
  color: black;
  background-color: white;
  line-height: 2.188rem;
  font-weight: 400;
  /* font-size: 20px; */
  font-size: 1.2rem;
}

.service1-6 ul {
  font-weight: 600;
  /* font-size: 25px; */
  /* font-size: 1.5rem; */
  font-size: 2vh;
  color: black;
}

.serviceFont {
  font-weight: 400;
  font-size: 1.125rem;
  color: black;
}

.service1-6 ul,
.service1-6 ol {
  padding-left: 0.625rem;
}

.main--servicegroup {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 20rem 20rem;
  /* background-color: white; */
  border-radius: 8px;
  width: 80%;
  column-gap: 0.2rem;
  row-gap: 2rem;
  /* top: 1.5rem; */
  justify-content: center;
  min-height: 100vh;
  /* height: auto; */
  margin: 0 auto 20px;
  font-weight: 600;
}

.main--servicegroup--item {
  width: 93%;
  height: auto;
  margin: 2rem auto 0;
  text-align: center;
  padding: 2.2rem 0.5rem 0.2rem;
  color: blue;
  background-color: whitesmoke;
  box-shadow: 5px 5px 25px gray;
}

.servicetext {
  font-size: 1.3rem;
}

.main--servicegroup--item:hover {
  font-size: 1.8rem;
  border-color: orange;
  background-color: white;
}

.service-font2 {
  color: blue;
  font-size: 0.8rem;
}

.link-button {
  background-color: dodgerblue;
  color: white;
  font-size: 0.7rem;
  height: 3.75rem;
  width: auto;
  padding: 0.625rem;
  border-radius: 5px;
}

.link-button:hover {
  background-color: blue;
}

hr {
  width: 100%;
}

.services div p {
  color: blue;
}

#service-content,
#service-content1,
#service-content2,
#service-content3,
#service-content4,
#service-content5 {
  /* display: static;	 */
  padding-top: 5rem;
  margin-top: -5rem;
}

.services img {
  width: 40%;
  height: 200px;
}

.tutors {
  position: absolute;
  top: 12.5rem;
  width: 80%;
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 100;

  /* SetMiddle */
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);  */
}

.tutors>div {
  background-color: #f1f1f1;

}

footer {
  position: relative;
  width: 100%;
  height: 45px;
  margin: 0;
  background-color: dodgerblue;
}

.copyright {
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  font-size: 0.7rem;
  font-weight: 800;
  line-height: 0.7rem;
  color: white;
}

.footer {
  position: relative;
  width: 100%;
  background-color: dodgerblue;
  color: white;
  margin-top: 1.25rem;
  height: auto;
  padding-top: 0.938rem;
  padding-bottom: 1.875rem;
}

.footer--full {
  margin: 0 auto;
  padding-top: 1.25rem;
  width: 80%;
  display: grid;
  grid-template-columns: 40% 35% 25%;
  color: white;
  font-weight: 100;
}

.footer--item {
  width: 100%;
  line-height: 1.6rem;
  color: white;
}

.footer--item ul :not(:nth-child(1)) {
  font-size: 0.85rem;
  font-weight: 300;
}

.footer--item2 {
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  text-align: center;
  line-height: 3rem;
}

.footer-header {
  font-size: 1.1rem !important;
  color: white;
}

.footer--middle--header {
  font-size: 2.5rem;
}

.footer--middle div {
  font-size: 2.2rem;
}

.footer__sidebar--right {
  row-gap: 1rem;
}

.footer--download {
  font-size: 1.4rem;
}

.footer--item {
  display: flex;
  flex-direction: column;
  color: white;
  padding-left: 10px;
  font-weight: 200;
}

.footer--item1--header {
  font-size: 1.1rem;
}

.footer--other {
  display: flex;
  width: 100%;
  color: white;
  font-weight: 500;
  line-height: 1.6rem;
}

.footer--other div a {
  font-size: 1rem;
  color: red;
}

.footer--other a:hover {
  color: orange !important;
  font-size: 1rem;
}

.footer-map {
  position: absolute;
  /* display: block; */
  width: 100%;
  height: 100%;
  /* height: 250px; */
  top: 1.875rem;
}

.map {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 70%;
  height: 20rem;
}

.indent {
  text-indent: 10%;
}

.footer--copyright {
  position: relative;
  bottom: 0;
  width: 100%;
  min-height: 2.813rem;
  background-color: dodgerblue;
  color: white;
  font-size: 0.7rem;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copyRight {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 2.813rem;
  background-color: dodgerblue;
  color: white;
  font-size: 0.7rem;
  font-weight: 500;
  margin: 10px auto;
  top: 9.688rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copyRight2 {
  width: 100%;
  height: 3.125rem;
  background-color: deepskyblue;
  color: white;
  font-size: 0.9rem;
  font-weight: 500;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 4.375rem;
  /* position: fixed;
	bottom: 0; */
}

.cr-bottom {
  position: relative;
  top: 48.125rem;
  /* bottom: 0; */
  margin: 0 auto;
}

.underbar {
  width: 15%;
  background: orange;
  padding: 2px 0;
  margin-left: 7%;
  transition: 0.1s ease;
}

.underbar1:hover~.underbar {
  position: relative;
  margin-left: 32%;
}

.underbar2:hover~.underbar {
  margin-left: 54%;
}

.underbar3:hover~.underbar {
  margin-left: 80%;
}

.img-service-lg {
  width: 14.375rem;
  height: 9.375rem;
  border-radius: 5px;
}

.img-service-md {
  width: 12.5rem;
  height: 7.5rem;
  border-radius: 5px;
}

.acctax-logo {
  width: 6.25rem;
  height: 6.25rem;
}

td {
  border: 1 solid black;
}

.content--acctaxintro {
  position: relative;
  top: 8.75rem;
  width: 60%;
  /* min-height: 600px; */
  height: 83vh;
  background-color: whitesmoke;
  color: black;
  margin: 0 auto;
  padding: 1.25rem;
  font-size: 1.3rem;
  font-weight: 300;
}

.content--acctaxintro-header {
  font-weight: 500;
  color: blue;
}

.footer--acctax {
  position: relative;
  width: 80%;
  margin: 2rem auto;
  height: 600px;
  display: flex;
  flex-direction: column;
  background-color: dodgerblue;
}

.footer--top {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  /* justify-content: center;
  align-items: center; */
  align-items: start;
  background-image: url("../pro-acctax-1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: dodgerblue;
  background-position: center;
}

.footer--bottom {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 25% 25% 30% 20%;
  height: auto;
  margin: 0 auto;
  padding: 1rem 0.3rem;
  /* display: flex;
  flex-direction: row;
  column-gap: 2rem; */
  background-color: dodgerblue;  
  /* justify-content: center; */
}

.footer--bottom div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

/*.footer--bottom div:nth-child(2) {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  align-items: center;
  justify-content: end; 
}*/

.footer--program {
  position: relative;
  width: 80%;
  margin: 0 auto;
  height: 31.25rem;
  display: grid;
  grid-template-columns: 7.5fr 2.5fr;
  background-image: url("business-g1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.footer--middle {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  width: 100%;
  margin: auto;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-weight: 500;
  color: white;
}

.footer--middle div {
  font-size: 2rem;
}

.footer__sidebar--right {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
}

.footer__sidebar--right--top {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.footer__sidebar--right--top:hover {
  font-size: 1.5rem;
}

.footer__sidebar--right--top div:hover {
  font-size: 1.2rem;
}

.accnew2 {
  width: 80%;
  margin: 0 auto;
  height: auto;
  display: grid;
  grid-template-columns: 1fr;
  background-image: url("business-g1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  top: 6.875rem;
  justify-content: center;
  text-align: center;
  padding: 1.875rem;
  font-size: 1.25rem;
  font-weight: 500;
  color: white;
  line-height: 2.813rem;
}

.accnew-item-header {
  font-size: 2.5rem;
  font-weight: 500;
  height: auto;
  line-height: 3.125rem;
  text-align: center;
  z-index: 99;
}

.accnew-line {
  width: 30%;
  display: grid;
  grid-template-columns: repeat(2, 300px);
  background-color: whitesmoke;
  color: dodgerblue;
  border-radius: 6px;
  padding-left: 6.25rem;
  justify-content: center;
  justify-self: center;
}

.accnew-col2 {
  text-align: left;
  padding-left: 3.75rem;
}

.accnew-line-group {
  position: relative;
  width: 50%;
  height: 100%;
  /* display: flex;   */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background-color: orange;
  opacity: 0.7;
  color: blue;
  border-radius: 6px;
  justify-self: center;
  align-items: self-start;
  padding: 0 20px;
}

.accnew-download-text {
  font-size: 1rem;
}

.accnew-problem {
  padding: 1.25rem;
  color: orange;
  font-weight: 700;
}

.acctaxHeader {
  color: white;
  font-weight: 800;
  font-size: 2.813rem;
  margin: 0px;
}

.download {
  width: 100%;
  display: flex;
  column-gap: 3.125rem;
  justify-content: center;
  align-items: center;
}

.download-button {
  font-size: 1rem;
  font-weight: 600;
}

.aboutus {
  position: relative;
  width: 60%;
  /* height: 900px; */
  background: white;
  color: black;
  top: 5rem;
  margin: 3.75rem auto;
  padding: 2.5rem 5rem;
  line-height: 1.875rem;
  font-weight: 400;
  font-size: 1rem;
  /* border-radius: 10px; */
}

.content__default--aboutus {
  position: relative;
  width: 60%;
  margin: 0 auto 3rem;
  padding: 2.5rem 2.5rem 2.5rem 4.063rem;
  top: 1.25rem;
  /* height: 90vh; */
  height: auto;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7rem;
  color: black;
  border-radius: 5px;
  background-color: white;
}

.content__default--aboutus>div:first-child {
  display: flex;
  justify-content: flex-end;
  background-color: whitesmoke;
}

.content__default--aboutus>div:nth-child(3)::first-letter {
  font-size: 2.5rem;
}

.about-us-acc {
  display: grid;
  grid-template-columns: 6fr 4fr;
  width: 100%;

}

.aboutus2 div {
  letter-spacing: 0.3px;
  /* text-transform: capitalize; */
}


.aboutus-header {
  color: dodgerblue;
  font-size: 1.2rem;
  font-weight: 700;
}

.acctax button {
  color: white;
}

.previous,
.previous-intro {
  position: absolute;
  display: block;
  float: right;
  font-size: 0.5rem;
  font-weight: 600;
  letter-spacing: 1px;
  /*position: relative;  
  top: 70%;*/
  right: 10px;
  bottom: 10px;
}

.login {
  position: relative;
  width: 25%;
  margin: 0 auto;
}

.sidebar {
  margin: 0;
  padding: 0;
  width: 15%;
  background-color: blue;
  position: fixed;
  height: auto;
  overflow: auto;
}

.sidebar a {
  display: block;
  padding: 1rem;
  color: white;  
  text-decoration: none;
}

.sidebar a.active {
  background-color: green;
  color: white;
}

.sidebar a:hover:not(.active) {
  background-color: whitesmoke;
  color: blue;
}

div.content {
  padding-left: 20%;

}

.mapaccount {
  display: flex;
  justify-content: center;   /* จัดกึ่งกลางในแนวนอน */
  align-items: center;       /* จัดกึ่งกลางในแนวตั้ง */
  width: 80%;                /* กำหนดให้กว้าง 80% ของหน้าจอ */
  height: 500px;             /* กำหนดความสูง */
  margin: 0 auto;            /* จัดกึ่งกลางทั้งแนวนอน */
  position: relative;
}

.mapaccount iframe {
  width: 100%;               /* กำหนดให้ iframe เต็มความกว้างของ .mapaccount */
  height: 100%;
  border: 0;                 /* ลบเส้นขอบ */
}
/*Responsive
1200px
1132px
900px
786px
678px
552px
480px
424px
*/

@media only screen and (max-width: 1200px) {
  .intro-header,
  .intro-container,
  .grid-container,
  .grid-fluid,
  .footer--full,
  .main--servicegroup,
  .service1-6,
  .footer--program {
    width: 98%;
  } 

  .intro-container,
  .main--servicegroup {
    column-gap: 10px;
  }

  .grid-container {
    column-gap: 2rem;
  }

  .content__header--service,
  .content__header--service,
  .intro-item,
  .footer--program,
  .content--service {
    width: 100%;
  }

  .header--step header {
    width: 100%;
  }

  .footer--middle div {
    font-size: 2.2rem;
  }

  .main--intro {
    width: 96%;
  }

  .content__default--aboutus {
    width: 96%;
  }

  .footer--acctax {
    width: 100%;
    font-size: 0.5rem;
  }
}

/* 1024 */
@media only screen and (max-width: 1132px) {
  .intro {
    grid-template-columns: 65% 35%;
  }

  .intro-item u {
    font-size: 1rem;
  }

  /* .intro--text {
    font-size: 3rem;
  } */

  .font--menu {
    font-size: 1.4rem;
  }

  .content__header--service {
    font-size: 1.25rem;
  }

  .content--service li {
    font-size: 1.00rem;
    font-weight: 600;
  }

  .footer--middle--header {
    font-size: 2.1rem;
  }

  .footer--middle div {
    font-size: 1.8rem;
  }

  .footer--download {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 900px) {
  .header__menu  {
    height: 110px;
  }   

  .font--menu {
    font-size: 1.1rem;
  }

  .header__menu--right--top {
    font-size: 0.8rem;    
  } 

  .header__menu--right--default {
    height: 50%;
    margin: 0 auto;
    padding-bottom: 0;
  }

  .header__menu--right--default ul li {
    align-items: end;
  }

  .logo-com {
    height: 110px;
  }

  .carousel {
    max-height: 550px;   
  }  
  
  .carousel-item img {      
    height: 550px;   
  }

  .intro-container {
    width: 98%;
    column-gap: 15px;
    grid-template-columns: repeat(2, 1fr);
  }

  .intro-item {
    width: 90%;
  }

  .intro {
    margin-top: 0;
  }

  .footer--bottom {
    display: grid;
    grid-template-columns: 25% 30% auto;
    height: auto;
    padding-bottom: 0.5rem;
    margin: 0;
  }  

  .footer--bottom div {
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.1rem;
  }
  
  /* .footer--bottom div:nth-child(2) {
    height: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    align-items: end;
    justify-content: end; 
  } */

  .footer--middle div {
    font-size: 1.65rem;
  }

  .footer__sidebar--right--top {
    width: 90%;
  }

  .footer--download {
    font-size: 1.5rem;
  }

  .intro--right {
    width: 98%;
  }

  .intro-item {
    width: 100%;
  }

  .intro-item u {
    font-size: 1.4rem;
  }

  /* .intro {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    line-height: 2.4rem;
  }

  .intro--right {
    width: 50%;
    height: 50%;
    row-gap: 1rem;
  }

  .intro--text {
    font-size: 2.9rem;
  }

  .intro--tel {
    width: 100%;
  } */

  .intro-item-header {
    font-size: 1.3rem;
  }

  .footer--item2 {
    font-size: 1rem;
  }

  .footer--program {
    height: auto;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }

  .footer--middle {
    padding-top: 1rem;
  }

  .footer__sidebar--right {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: center;
  }

  .footer__sidebar--right--top {
    width: 50%;
    align-items: center;
    row-gap: 1rem;
  }

  .icon-intro-lg {
    font-size: 4rem;
  }

  .footer--full {
    display: flex;
    flex-direction: column;
    padding-top: 0px;
  }
}

@media only screen and (max-width: 786px) {  
  .header__menu {
    height: 80px;
  }

  .header__menu--right {
    height: 80px;
  }

  .logo-com {
    /* width: 40%; */
    height: 80px;
  }

  .icon-sm {
    font-size: 0.8rem;
  }

  .headerFull-text p {
    font-Size: 1.6rem;
  }

  .headerFull-text p:nth-child(2) {
    font-Size: 3rem;
  }

  .grid-container {
    display: flex;
    flex-direction: column;
    width: 93%;
  }

  .footer--bottom {
    grid-template-columns: 50% auto;
  }
}

@media only screen and (max-width: 678px) {
  .font--menu {
    font-size: 1rem;
  }

  .font--menu--count,
  .header__menu--right--top {
    font-size: 0.6rem;
  }

  .intro-container {
    column-gap: 0.5rem;
  }

  .intro-item {
    width: 90%;
  }

  .intro-item u {
    font-size: 1.1rem;
  }

  .intro--text {
    font-size: 2.5rem;
    /* letter-spacing: 0.5rem; */
  }

  .content__header--service {
    font-size: 1.3rem;
  }

  .main--servicegroup {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 15rem);
    row-gap: 0.2rem;
    height: auto;
  }

  .main--servicegroup--item {
    height: auto;
    margin-top: 20px;
    padding-top: 20px;
  }

  .servicetext {
    font-size: 1.3rem;
  }

  .icon-sm {
    font-size: 1.2rem;
  }

  /* .intro-item-header {
    font-size: 1.2rem;
  } */

  .icon-intro-lg {
    font-size: 3rem;
  }

  .proposal-header {
    font-size: 1.1rem;
  }

  /* .footer--bottom {
    position: relative;
    margin-top: 0;
    display: flex;
    flex-direction: row;
    padding: 1rem 0;
    text-align: center;
    justify-content: center;
  } */
}

@media only screen and (max-width: 552px) {
  /* .font--menu {
    font-size: 1rem;
  }

  .header__menu--right--top 
  {
    font-size: 0.8rem;
  }

  .header__menu--right {
    height: auto;
  } */

  /* .intro-container {
    display: flex;
    flex-direction: column;
  }*/

  .header__menu {
    /* grid-template-columns: 15% 85%; */
    height: 60px;
  }

  .header__menu--right {
    height: 50px;
  }

  .header__menu--right--default {
    column-gap: 0.5rem;
  }  

  .header__menu--right--default ul>li {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
  }

  .logo-com {    
    height: 60px;
  }

  .carousel {
    max-height: 500px;   
  }  
  
  .carousel-item img {      
    height: 500px;   
  }

  .font--menu {
    font-size: 0.8rem;
  }

  .header__menu--right--top {
    font-size: 0.6rem;
  }

  .intro-item {
    width: 100%;
  }

  /*.intro-item u {
    font-size: 1.1rem;
  } */

  .intro--left>div {
    font-size: 1.1rem;
  }

  .intro-item-header {
    line-height: 2rem;
  }

  .icon-lg {
    font-size: 4.2rem;
  }

  .icon-sm {
    font-size: 1rem;
  }

  .servicetext {
    font-size: 1rem;
  }

  /* .content--service {
    display: flex;
    flex-direction: column;
  } */

  /* .footer--middle--header {
    font-size: 2rem;
  }

  .footer--middle div {
    font-size: 1.5rem;
  }

  .footer--download {
    font-size: 1rem;
  } */

  .content--service {
    /* display: flex;
    flex-direction: column; */
    line-height: 1.7rem;
  }

  .content__header--mian {
    font-size: 1rem;
    line-height: 2rem;
  }

  .content__header--service {
    font-size: 1.2rem;
  }

  .img-service-lg {
    width: 10rem;
    height: 8rem;
  }

  .service--header {
    width: 98%;
  }

  /* .footer--bottom {
    display: flex;
    flex-direction: row;  
  } */
}

@media only screen and (max-width: 480px) {
  .header__menu {
    /* grid-template-columns: 15% 85%; */
    height: 45px;
    font-size: 0.8rem;
  }

  .header__menu--right {
    height: 45px;
  }

  .header__menu--right--default {
    column-gap: 0.5rem;
  }  

  .header__menu--right--default ul>li {    
    padding: 0.01rem 0.25rem;
  }

  .logo-com {    
    height: 45px;
  }
  
  .header__menu--right--top {
    font-size: 0.5rem;
  }

  .footer--bottom {
    display: flex;
    flex-direction: column;
  }
  

  .intro-item u {
    width: 100%;
    font-size: 1rem;
  }

  .content__header--service {
    font-size: 1.
    05rem;
  }

  .download-text {
    font-size: 0.8rem;
  }

  .copyright {
    font-size: 0.6rem;
  }
}

@media only screen and (max-width: 430px) {
  .header__menu--right--default ul li {
    padding: 0 0.12rem;
  }

 .font--menu{
    font-size: 0.65rem;
  }

  .intro-item u {
    width: 100%;
    font-size: 0.8rem;
  }

  .proposal-header {
    font-size: 0.8rem;
  }

  .content__header--service {
    font-size: 0.94rem;
  }

  /* .footer--bottom {
    column-gap: 0.5rem;
    padding: 0.5rem;
    justify-content: space-around;
  } */
}

@media only screen and (max-width: 430px) {
  .header__menu {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  /* .header__menu--right--top {    
    font-size: 0.4rem;
    margin-right: 0.1rem;
  }

  .font--menu {
    font-size: 0.5rem;
  } */

  .logo-com {    
    height: 2rem;
    width: 20%;
  }

  .header__menu--right--default ul li {
    padding: 0 0.2rem;
  }
}